import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
// import { mainUrl } from '../../../js/config';
import dataForHeader from '../../../information/dataForHeader.json';
import logo_blue from '../../../images/header/logo_blue.svg';
import arrow from '../../../images/arrow_down.svg';

import './Header.scss';

const Collapse = ({ href, isOpen, onToggle, title, height, children }) => {
  const contentRef = useRef(null);

  return (
    <div className="collapse-header">
      <Link
        to={href}
        className="collapse-title"
        activeClassName='open'
        partiallyActive={!(href === '/') && true}
      >
        {title}
      </Link>
      <img className={`collapse-icon ${isOpen ? "open" : ""}`} src={arrow} onClick={onToggle} />
      <div
        className="collapse-content"
        style={{
          height: isOpen ? `${height}px` : "0",
        }}
        ref={contentRef}
      >
        <div className="collapse-inner">{children}</div>
      </div>
    </div>
  );
};

const SubLinks = ({ subItems }) => {
  return subItems.map(({ id, href, content, isRedirect }) => {
    if (isRedirect) {
      return (
        <a key={id} href={href} target="_blank" rel="noopener noreferrer">
          {content}
        </a>
      );
    }

    return (
      <Link
        key={id}
        to={href}
        activeClassName='selected'
        partiallyActive={!(href === '/') && true}
      >
        {content}
      </Link>
    );
  });
}

const Header = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(null);

  useEffect(() => {
    const body = document.querySelector('body');
    if (isExpanded) {
      body.setAttribute('style', 'overflow: hidden');
    } else {
      body.removeAttribute('style');
    }

    return () => {
      body.removeAttribute('style');
    };
  }, [isExpanded]);

  const toggleShow = () => {
    setIsExpanded(prevState => !prevState);
  };

  const handleToggleCollapse = (id) => {
    setOpenCollapse(prev => (prev === id ? null : id));
  };

  return (
    <div className="header-container">
      <div className="header-center">
        <Link
          to='/'
          className="header-logo-container"
        >
          <img src={logo_blue} alt='OS-system logo' className='header-logo' />
        </Link>
        <div className={isExpanded ? 'header-menu active' : 'header-menu'}>
          <div className='header-list'>
            {dataForHeader.map(item => {
              const { id, href, itemClass, content, subItems } = item;
              const isDropdown = subItems?.length > 0;
              if (content === 'Blog') {
                return (
                  <a key={id} href={href} className={itemClass}>
                    {content}
                  </a>
                );
              }
              
              if (isDropdown) {
                return (
                  <div className="dropdown">
                    <Link
                      key={id}
                      to={href}
                      className={itemClass}
                      activeClassName='selected'
                      partiallyActive={!(href === '/') && true}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {content}
                      <img className='dropdown-arrow' src={arrow} />
                    </Link>
                    <div className="dropdown-content">
                      <div className="dropdown-links">
                        <SubLinks subItems={subItems} />
                      </div>
                    </div>
                  </div>
                )
              }

              return (
                <Link
                  key={id}
                  to={href}
                  className={itemClass}
                  activeClassName='selected'
                  partiallyActive={!(href === '/') && true}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {content}
                </Link>
              )
            })}
          </div>
          <Link
            offset='0'
            to='/contact/'
            className='header-contact-button-container'
            onClick={() => window.scrollTo(0, 0)}
          >
            <div className='header-contact-button'>Contact us</div>
          </Link>
        </div>
        <div
            className={`burger-menu ${isExpanded ? "active" : ""}`}
            onClick={toggleShow}
        >
            <span></span>
            <span></span>
            <span></span>
        </div>
      </div>
      <div className={`mobile-menu ${isExpanded ? "active" : ""}`}>
        <div className="link-list">
          {dataForHeader.map(({ id, href, itemClass, content, subItems, height }) => {
            const isDropdown = subItems?.length > 0;
            if (isDropdown) {
              return (<div className="link-container" key={id}>
                <Collapse
                  href={href}
                  title={content}
                  height={height}
                  isOpen={openCollapse === id}
                  onToggle={() => handleToggleCollapse(id)}
                >
                  <SubLinks subItems={subItems} />
                </Collapse>
              </div>);
            }

            if (content === 'Blog') {
              return (
                <div className="link-container" key={id}>
                  <a key={id} href={href} className={itemClass}>
                    {content}
                  </a>
                </div>
              );
            }

            return (
              <div className="link-container" key={id}>
                <Link
                    key={id}
                    to={href}
                    className={itemClass}
                    activeClassName='selected'
                    partiallyActive={!(href === '/') && true}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {content}
                  </Link>
              </div>
            )
          })}
          <Link
            offset='0'
            to='/contact/'
            className='header-contact-button-container'
            onClick={() => window.scrollTo(0, 0)}
          >
            <div className='header-contact-button'>Contact us</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;